import React from "react";
import { Button } from "@atoms";

import SectionHeading from "./SectionHeading";
import CaseStudyGrid from "./CaseStudyGrid";

const RelatedCaseStudies = ({ caseStudies }) => {
  return (
    <section className="py-10 sm:py-20">
      <div className="flex flex-col items-center gap-10 sm:gap-20">
        <SectionHeading heading="Related case studies" />
        {!!caseStudies?.length && <CaseStudyGrid cards={caseStudies} />}
        <Button color="pink" to="/impact/case-studies">
          View all case studies
        </Button>
      </div>
    </section>
  );
};

RelatedCaseStudies.defaultProps = {};

export default RelatedCaseStudies;
