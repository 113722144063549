import React from "react";
import classNames from "classnames";
import { Text, Button, Container } from "@atoms";

const SectionHeading = ({ color, heading, descriptor, button, variant }) => {
  const isDark =
    color === "bg-pink" ||
    color === "bg-purple" ||
    color === "bg-teal-dark" ||
    color === "bg-teal-darker" ||
    color === "bg-black";

  return (
    <Container variant="xs">
      <div
        className={classNames(
          "flex w-full flex-col items-center justify-center gap-6 sm:px-4",
          {
            "text-white": isDark,
          }
        )}
      >
        <Text variant={variant} className="text-center">
          {heading}
        </Text>
        {!!descriptor && (
          <Text
            variant="lg"
            className="whitespace-pre-line text-center font-medium"
          >
            {descriptor}
          </Text>
        )}
        {!!button?.url && (
          <Button to={button.url} color={isDark ? "blackPink" : "pink"}>
            {button.text}
          </Button>
        )}
      </div>
    </Container>
  );
};

SectionHeading.defaultProps = {
  variant: "h2",
};

export default SectionHeading;
