import React from "react";
import classNames from "classnames";
import useCaseStudiesData from "@staticQueries/CaseStudiesQuery";
import { CaseStudyCard } from "@molecules";
import { Container } from "@atoms";

const CaseStudyGrid = ({ caseStudies, columnCount, filters }) => {
  const defaults = useCaseStudiesData();
  let cards = caseStudies?.length ? caseStudies : defaults;
  if (filters?.length > 0) {
    cards = cards?.filter(card =>
      card.audiences?.map(a => a.uid)?.some(a => filters.includes(a))
    );
  }

  return (
    <Container>
      <div className="flex flex-wrap justify-center gap-5">
        {cards?.map(c => (
          <div
            key={c.uid}
            className={classNames("flex-grow md:flex-[0_1_calc(50%-.625rem)]", {
              "lg:flex-[0_1_calc(33.333%-.875rem)]": columnCount > 2,
            })}
          >
            <CaseStudyCard key={c.uid} vertical={columnCount > 2} {...c} />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default CaseStudyGrid;
