import { useStaticQuery, graphql } from "gatsby";
import resolveCaseStudy from "@dataResolvers/resolveCaseStudy";

const query = graphql`
  query CaseStudiesQuery {
    craft {
      entries(section: "caseStudies") {
        ... on Craft_caseStudies_default_Entry {
          uid
          url
          type: sectionHandle
          title
          heading: heading0
          metaDescription
          metaImage {
            ...ImageFragment
          }
          audiences {
            title
            uid
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  const caseStudies = entries?.map(resolveCaseStudy);
  return caseStudies;
};

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
